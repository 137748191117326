/* global maplibregl */

Apt.fn.extend('maps', {}, {
	/**
	 * Create new map instance
	 *
	 * @param {$} target
	 * @param {Object} options
	 * @param {Function} fn
	 */
	create: function(target, options, fn) {
		var scope = this,
			center = options.center || scope.default.center,
			zoom = options.zoom || 5,
			maxZoom = options.maxZoom || 18.4,
			style = scope.conf.style,
			instance;

		if (center && center[1] < -90 || center[1] > 90) {
			options.center = center = center.reverse();
		}

		scope.$parent = $(target);

		// Check for available instance
		if (LS.stash.maps) {
			LS.stash.maps.some(function(el) {
				if (! el.active) {
					instance = el;

					return true;
				}
			});

			if (instance) {
				var map = instance.map;

				scope.$parent.append(instance.$container);

				requestAnimationFrame(function() {
					map.resize();
				});

				map.setMaxZoom(maxZoom);
				map.dragRotate[options.dragRotate ? 'enable' : 'disable']();

				if (options.bounds) {
					map.fitBounds(options.bounds, {
						animate: false
					});
				} else {
					map.jumpTo({
						center: center,
						zoom: zoom
					});
				}

				if (style !== instance.style) {
					map.setStyle(scope.getStyling(style), true);
				}

				scope.active = instance.active = true;

				fn(instance);

				return;
			}
		}

		var $container = $('<div class="map-container"/>');

		scope.$parent.append($container);

		instance = {
			$container: $container,
			active: true,
			bounced: [],
			events: [],
			layers: [],
			sources: [],
			map: new maplibregl.Map($.extend({
				attributionControl: false,
				bearingSnap: 35,
				center: center,
				container: $container[0],
				doubleClickZoom: false,
				keyboard: false,
				maxPitch: 65,
				maxZoom: maxZoom,
				minZoom: 3,
				pitchWithRotate: false,
				style: scope.getStyling(style),
				touchPitch: false,
				zoom: zoom
			}, options))
		};

		LS.stash.maps.push(instance);

		scope.active = true;

		fn(instance);
	},

	/**
	 * Reset and store map instance
	 */
	stash: function() {
		var scope = this,
			pub = scope.$public,
			instance = scope.instance;

		if (! scope.active) {
			return;
		}

		$.fetch.abort('map');

		scope.map.stop();

		pub.unbounce();
		pub.unbind();

		instance.style = scope.conf.style;

		if (scope.uid) {
			$.events.reset(scope.uid);
		}

		instance.$container.appendTo('$stash');

		scope.minimize();

		if (scope.inspect) {
			scope.inspect.$destroy();

			scope.inspect = null;
		}

		pub.hideTooltip();
		pub.removeDraw();

		scope.removePitch();
		scope.setPitch(false, true);

		scope.removeScale();
		scope.removeOptions();

		scope.resetTiles();

		scope.removeLayer();
		scope.removeSource();

		if (scope.model) {
			scope.$overlay.empty();

			scope.model.$destroy();

			scope.$overlay = scope.model = null;
		}

		scope.$parent.empty();

		scope.$parent = null;

		scope.active = instance.active = false;
	}
});