Apt.fn.extend('maps', {
	/**
	 * Hover selected pin
	 *
	 * @param {Array} [center]
	 */
	hover: function(center) {
		var scope = this,
			priv = scope.$private,
			options = {
				validate: false
			},
			opacity = 1,
			radius = 8;

		if (
			! priv.active ||
			! scope.map.getLayer('markers')
		) {
			return;
		}

		scope.$public.ready(function() {
			priv.createHoverLayer();

			if (scope.hoverFrame) {
				cancelAnimationFrame(scope.hoverFrame);
				clearTimeout(scope.hoverTimeout);

				scope.map.setPaintProperty('hovered', 'circle-opacity', 0, options);
				scope.map.setPaintProperty('hovered', 'circle-radius', radius, options);
			}

			scope.map.getSource('hovered')
				.setData(center ? {
					type: 'FeatureCollection',
					features: [{
						type: 'Feature',
						properties: {},
						geometry: {
							type: 'Point',
							coordinates: center
						}
					}]
				} : priv.empty);

			if (center) {
				var animate = function () {
					scope.hoverTimeout = setTimeout(function () {
						if (! scope.map.getLayer('hovered')) {
							return;
						}

						scope.hoverFrame = requestAnimationFrame(animate);

						radius += (40 - radius) / 25;
						opacity -= (0.9 / 25);

						scope.map.setPaintProperty('hovered', 'circle-opacity', Math.max(opacity, 0), options);
						scope.map.setPaintProperty('hovered', 'circle-radius', radius, options);

						if (opacity <= 0) {
							radius = 5;
							opacity = 1;
						}
					}, 20);
				};

				animate();
			}
		});
	}
}, {
	/**
	 * Create hover layer
	 */
	createHoverLayer: function() {
		var scope = this;

		if (scope.map.getSource('hovered')) {
			return;
		}

		scope.addSource('hovered', {
			type: 'geojson',
			data: scope.empty
		});

		scope.addLayer({
			id: 'hovered',
			type: 'circle',
			source: 'hovered',
			paint: {
				'circle-color': '#f2f9f5',
				'circle-opacity-transition': {
					duration: 0
				},
				'circle-radius-transition': {
					duration: 0
				}
			}
		}, 'markers');
	}
});