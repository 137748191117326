/* global maplibregl */

Apt.fn.extend('maps', {
	/**
	 * Resize map to fit container
	 *
	 * @param {Function} [fn]
	 */
	resize: function(fn) {
		var scope = this,
			priv = scope.$private;

		if (! priv.active || priv.working) {
			return;
		}

		if (fn) {
			scope.bind('map', 'resize', function() {
				fn();
			}, true);
		}

		requestAnimationFrame(function() {
			priv.map.resize();
		});
	},

	/**
	 * Constrain map to bounds
	 *
	 * @param {Array} [bounds]
	 * @param {Object} [options]
	 * @param {Boolean} [options.animate=false]
	 * @param {Boolean} [options.duration=800]
	 * @param {Function} [options.fn]
	 * @param {Number} [options.maxZoom]
	 * @param {Number} [options.minZoom]
	 * @param {Boolean} [options.pad=true]
	 */
	constrain: function(bounds, options) {
		var scope = this,
			priv = scope.$private,
			conf = {
				animate: options.animate || false,
				duration: options.duration || 800
			};

		if (! priv.active) {
			return;
		}

		scope.hideTooltip();

		if (options.fn || options.minZoom) {
			scope.bind('map', 'moveend', function() {
				try {
					$.exec(options.fn);

					if (options.minZoom && priv.map.getZoom() < options.minZoom) {
						priv.map.setZoom(options.minZoom);
					}
				} catch (e) {
					//
				}
			}, true);
		}

		if (options.maxZoom) {
			conf.maxZoom = options.maxZoom;
		}

		bounds = scope.parseBounds(bounds || priv.default.bounds);

		if (! bounds) {
			return;
		}

		priv.map.fitBounds(
			scope.padBounds(bounds, options.pad || true), conf
		);
	},

	/**
	 * Center map to specified location
	 *
	 * @param {Array} [center]
	 * @param {Object} [options]
	 */
	center: function(center, options) {
		var scope = this,
			priv = scope.$private,
			conf = $.extend({
				center: center || priv.default.center,
				maxDuration: 2000,
				panel: false,
				speed: 2.8,
				zoom: 14.4
			}, options),
			data = {
				originalEvent: {}
			};

		if (! priv.active) {
			return;
		}

		scope.hideTooltip();

		if (scope.toggle) {
			scope.toggle.disable();
		}

		if (conf.animate && $.screen.size() > 2) {
			scope.map.flyTo(conf, data);
		} else {
			scope.map.jumpTo(conf, data);
		}
	},

	/**
	 * Center map on contextual geometry
	 *
	 * @param {Function} [fn]
	 */
	position: function(fn) {
		var scope = this,
			priv = scope.$private,
			location = $.context('location'),
			bounds,
			zoom;

		if (! location) {
			if (scope.parsePosition()) {
				return;
			}

			bounds = priv.default.bounds;

			priv.location = null;
		} else {
			if (location.slug === priv.location) {
				return;
			}

			priv.location = location.slug;

			bounds = location.bounds;
			zoom = priv.default.minZoom[location.type];

			if (! bounds) {
				zoom = 11;

				scope.center(location.center, {
					zoom: zoom
				});

				$.exec(fn);

				return;
			}
		}

		scope.constrain(bounds, {
			animate: true,
			fn: fn,
			minZoom: zoom
		});
	},

	/**
	 * Get formatted map center point
	 *
	 * @param {Boolean} [round=false]
	 * @returns {Array}
	 */
	getCenter: function(round) {
		var center = this.map.getCenter();

		return round ? [
			LS.util.round(center.lng, 6),
			LS.util.round(center.lat, 6)
		] : [
			center.lng,
			center.lat
		];
	},

	/**
	 * Get formatted map boundary
	 *
	 * @param {(Boolean|Number)} [pad=false]
	 * @param {Boolean} [round=true]
	 * @returns {Array}
	 */
	getBounds: function(pad, round) {
		var scope = this,
			bounds = scope.parseBounds(
				scope.map.getBounds()
			);

		return bounds ?
			scope.padBounds(bounds, pad, round) : null;
	},

	/**
	 * Pad bounds with extra spacing
	 *
	 * @param {Array} bounds
	 * @param {(Boolean|Number)} [pad=0.05]
	 * @param {Boolean} [round=true]
	 * @returns {Array}
	 */
	padBounds: function(bounds, pad, round) {
		pad = pad || 0;

		if (bounds.length === 2) {
			bounds = bounds[0].concat(bounds[1]);
		}

		if (pad === true) {
			var $target = $(this.$private.conf.target),
				height = $.height($target) || 360,
				width = $.width($target) || 360;

			pad = Math.max(Math.max(
				Math.abs(bounds[0] - bounds[2]) / height,
				Math.abs(bounds[1] - bounds[3]) / width
			) * 90, 0.15);
		}

		bounds = [
			bounds[0] - pad,
			bounds[1] - pad,
			bounds[2] + pad,
			bounds[3] + pad
		];

		if (round === false) {
			return bounds;
		}

		return bounds.map(function(val) {
			return LS.util.round(val, 6);
		});
	},

	/**
	 * Normalize bounds into
	 *
	 * @param {(Array|Object)} bounds
	 * @returns {Array}
	 */
	parseBounds: function(bounds) {
		if (Array.isArray(bounds)) {
			if (bounds.length === 2) {
				return [
					bounds[0][0],
					bounds[0][1],
					bounds[1][0],
					bounds[1][1]
				];
			}

			return bounds;
		}

		if (typeof bounds !== 'object') {
			return null;
		}

		var lower = bounds._sw,
			upper = bounds._ne;

		return [
			lower.lng,
			lower.lat,
			upper.lng,
			upper.lat
		];
	},

	/**
	 * Get map zoom level
	 *
	 * @param {Boolean} [round=false]
	 * @returns {Number}
	 */
	getZoom: function(round) {
		var val = this.map.getZoom();

		return round ?
			LS.util.round(val, 2) : val;
	},

	/**
	 * Remove drawing
	 *
	 * @param {Boolean} [reset=false]
	 */
	removeDraw: function(reset) {
		var priv = this.$private,
			draw = priv.draw;

		if (draw) {
			if (reset) {
				draw.reset();
			}

			draw.$destroy();

			priv.draw = null;

			priv.model.$set('drawing', false);
		}
	},

	/**
	 * Execute function when map is ready
	 *
	 * @param {*} fn
	 * @param {Boolean} [listings=false]
	 * @returns {Object}
	 */
	ready: function(fn, listings) {
		var priv = this.$private,
			name = 'paint-' + LS.util.uid();

		priv[name] = setInterval(function() {
			// Check back if map is working
			if (priv.working) {
				return;
			}

			// Abort if instance isn't active
			if (! priv.active) {
				clearInterval(priv[name]);

				return;
			}

			// Check if listings are loading
			if (listings && priv.loading) {
				return;
			}

			// Continue if map is idle
			if (priv.map.isMoving() || ! priv.map.isStyleLoaded()) {
				return;
			}

			clearInterval(priv[name]);

			try {
				fn();
			} catch (e) {
				//
			}
		}, 25);

		return priv[name];
	},

	/**
	 * Get feature by ID
	 *
	 * @param {Number} id
	 * @returns {Object}
	 */
	getFeature: function(id) {
		var points = this.$private.layers.listings,
			i = 0,
			match;

		for (; i < points.length; i++) {
			if (points[i].id === id) {
				match = points[i];
				break;
			}
		}

		return match;
	},

	/**
	 * Check for WebGL support
	 *
	 * @returns {Boolean}
	 */
	supported: function() {
		if ($._win.WebGLRenderingContext) {
			var canvas = $._doc.createElement('canvas');

			try {
				var context = canvas.getContext('webgl2') || canvas.getContext('webgl');

				if (context && typeof context.getParameter === 'function') {
					return true;
				}
			} catch (e) {
				//
			}
		}

		return false;
	}
}, {
	/**
	 * Determine which polygons are currently in view
	 *
	 * @param {('subterritories'|'territories')} type
	 * @param {Object} [criteria]
	 * @returns {Array}
	 */
	getVisibleGeometry: function(type, criteria) {
		var features = this.getUnique(
			this.map.queryRenderedFeatures($.extend({
				layers: [type],
				validate: false
			}, criteria))
		);

		// Extract feature IDs
		return features.map(function(el) {
			return el.id;
		});
	}
});