Apt.fn.extend('maps', {}, {
	/**
	 * Bind mouse move listener
	 */
	watchMove: function() {
		var scope = this,
			pub = scope.$public;

		if (scope.conf.single) {
			return;
		}

		pub.bind('map', 'moveend', function(e) {
			if (! scope.active) {
				return;
			}

			var bounds = pub.getBounds();

			if (! $.equals(scope.bounds, bounds)) {
				scope.loadListings();

				if (e.originalEvent) {
					scope.updatePosition();
				}
			}

			scope.bounds = bounds;
		});
	},

	/**
	 * Observe history change events
	 */
	bindHistory: function() {
		var scope = this;

		$($._win).on('preload', function() {
			if (scope.conf.single) {
				return;
			}

			scope.setState(true);
		}, {
			namespace: scope.uid
		});
	},

	/**
	 * Set map loading state
	 *
	 * @param {Boolean} [done=false]
	 * @param {Boolean} [delay=false]
	 */
	setState: function(done, delay) {
		var scope = this;

		clearInterval(scope.loadState);

		scope.loading = done !== true;

		if (! scope.loading) {
			scope.$parent.removeClass('-is-loading -is-updating');

			return;
		}

		scope.loadState = setTimeout(function() {
			if (scope.$parent) {
				scope.$parent[0].classList.add('-is-updating');
			}
		}, delay ? 400 : 0);
	}
});