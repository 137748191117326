Apt.fn.extend('maps', {}, {
	/**
	 * Load listing data
	 */
	loadListings: function() {
		var scope = this;

		if (
			! scope.active ||
			scope.conf.single ||
			scope.conf.showPoints ||
			scope.loading ||
			scope.map.getZoom() < 9
		) {
			return;
		}

		if (! scope.subterritoryReady) {
			scope.initSubterritories();

			return;
		}

		scope.setState(false, true);

		scope.$public.ready(function() {
			if (scope.map.getZoom() < 9) {
				scope.setState(true);

				return;
			}

			scope.findListings();
		});
	},

	/**
	 * Query visible layer for listings
	 */
	findListings: function() {
		var scope = this,
			layer = scope.layers.subterritories,
			ids = scope.getVisibleGeometry('subterritories')
				.filter(function(el) {
					return layer.loaded.indexOf(el) === -1;
				});

		if (! ids.length) {
			scope.setState(true);

			return;
		}

		layer.loaded = layer.loaded.concat(ids);

		scope.fetchListings(ids);
	},

	/**
	 * Fetch listing data
	 *
	 * @param {Array} ids
	 */
	fetchListings: function(ids) {
		var scope = this,
			filters = LS.filters.get();

		delete filters.bounds;
		delete filters.location;
		delete filters.page;

		filters.subterritory_id = ids; // eslint-disable-line

		LS.api.get('listings/points', {
			namespace: 'map',
			data: filters,
			success: function(data) {
				var results = data.results;

				scope.layers.listings = results ?
					scope.layers.listings.concat(results) : [];

				scope.addMarkers(function() {
					scope.setState(true);
				});
			},
			error: function() {
				scope.setState(true);
			}
		});
	}
});